import React from "react";

import { graphql } from "gatsby";
import Layout from "../components/layout";
import PostCard from "../components/postCard";
import SEO from "../components/seo";

const IndexPage = ({
  data: {
    site,
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter((edge) => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map((edge) => <PostCard key={edge.node.id} post={edge.node} />);

  return (
    <>
      <SEO />
      <Layout>
        <div className="grids">{Posts}</div>
      </Layout>
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            thumbnail
            metaDescription
          }
        }
      }
    }
  }
`;
