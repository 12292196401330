import React from "react";
import { Link } from "gatsby";

const PostCard = ({ post }) => (
  <article className="card">
    <Link to={post.frontmatter.slug}>
      <div
        className="post-image"
        style={{
          backgroundImage: `url(${
            post.frontmatter.thumbnail || "/assets/default.jpg"
          })`,
        }}
      ></div>
      <h2 className="post-title">{post.frontmatter.title}</h2>
      <div className="post-date">{post.frontmatter.date}</div>
      <div className="post-description">{post.frontmatter.metaDescription}</div>
    </Link>
  </article>
);
export default PostCard;
